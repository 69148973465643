<template>
  <div class="page">
        <!-- <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="请输入关键词" @search="onSearch"/>
            </div>
        </div> -->
        <div class="" style="text-align:left;padding-left:20px">
            <a-button  class="fixedBtn addBtn" size="large" type="primary" @click="showAdd">添加轮播图</a-button>
        </div>
        <div v-infinite-scroll="getData"
               infinite-scroll-disabled="dataLoadStop"
               infinite-scroll-distance="50">
       <div class="lists" v-for="(item,index) in data" :key="index">
            <div class="list_row">
                 <img style="width:200px;display: block;" :src="ajaxUrl+'/scrm/index/getFile?id='+item.img">
                <!-- <div class="flex">
                    <div><label for="">分类id：</label>{{item.id}}</div>
                </div> -->
            </div>
            <div class="btn_con">
                <a-button type="primary" size="small" ghost @click="editType(item)">编辑</a-button>
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>
                
            </div>
        </div>
        </div>
         <a-modal v-model="visible" title="添加轮播图"  @ok="addType">
             <template>
                <img style="width:200px;display: block;" v-if="imageId" :src="ajaxUrl+'/scrm/index/getFile?id='+imageId">
                <label class="fileLabel" for="image"><a-icon type="upload" /> 上传轮播图 </label>
                <input type="file" id="image" ref="image" style="display:none" @change="imgUpload($event)">
                <div style="padding-top:15px;color:#999;font-size:13px">建议尺寸 <span style="color:red">750x400像素</span></div>
            </template>
        </a-modal>
         <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
    </div>
     
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime,ajaxUrl,fileUpload} from '../../../assets/js/request';
export default {
    data() {
        return {
            key: "",
            type: null,
            status: null,
            visible:false,
            ajaxUrl:ajaxUrl,
            onID:'', //编辑状态id
            data:[],
            addText:'', //添加分类名称
             imageId:'',
              // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
               pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    mounted: function(){
        this.getData();
    },
    methods: {
        // 搜索
        onSearch(){
            this.getData(this.key,this.type);
        },
        reset(){
            this.key = "";
            this.type = null;
            this.status = null;
        },
        getData() {
            requestXml("/jieb/Apisetting/carousellist","POST",(res) => {
                console.log(res);
                for(let i=0;i<res.list.length;i++){
                    res.list[i].created = getDateTime(res.list[i].created);
                }
                this.data = this.data.concat(res.list);
                if(res.page.maxpage>this.pagination.current){
                this.pagination.current += 1
                this.dataLoadStop = false
                }else{
                this.dataLoadNomore = true
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{
                  page: {
                        curpage: this.pagination.current,
                        pagesize: this.pagination.pageSize,
                    },
            })
        },
          // 分页
        pagesChange(current) {
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize);
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        //添加分类
        showAdd(){
            this.visible = true;
        },
          // 图片上传
        imgUpload(e){
            fileUpload(e,(res) => {
                console.log(res);
                this.imageId=res.id;
                 this.$refs.image.value =''
            })
        },
        addType(){
            // console.log(this.addText);
            if(this.imageId!=""){
            let url="/jieb/Apisetting/carouseledit"
            let data={'img':this.imageId}
            if(this.onID){
                url="/jieb/Apisetting/carouseledit"
                data.id=this.onID;
            }
            requestXml(url,"POST",(res) => {
                console.log(res)
                this.visible=false;
                this.onID='';
                this.addText='';
                 this.imageId='';
                this.getData();
            },data)
            }else{
                 this.$message.info('请先上传图片');
            }
         
        },
         // 编辑
        editType(res){
            this.onID=res.id;
            this.imageId=res.img;
            this.visible=true;
        },
        // 删除
        onDelete(id){
            requestXml("/jieb/Apisetting/carouseldel","POST",() => {
                this.pagination.current=1;
                this.data=[];
                this.getData();
            },{"id": id})
        }
    },
    
};
</script>
<style scoped>
    .page{
        margin-top: -15px;
        min-height: calc( 100vh + 15px );
        padding-top: 15px;
    }
   .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search{display: flex;justify-content: space-between;}
    .addBtn{width: auto;}
    .fixedBtn{
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 999;
        height: 43px;
        border-radius: 50px;
    };
    .action{display:inline-block;padding:5px 10px;margin:0 5px;background:#dceeff;border:1px solid #36a3ff;color:#36a3ff;border-radius:5px}
    .del{background:#ffdcdc;border-color:#ff4c4c;color:#ff4c4c}
    .add_title{padding: 15px 0;}
    .add_inp{height: 40px;margin-bottom: 60px;}
     .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    .lists .list_row div{
        /* height: 5rem;
        line-height: 5rem; */
        text-align: left;
    }
    .lists .list_row .flex{
        justify-content: space-between;
    }
    

    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
</style>

